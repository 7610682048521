export default {
  TRADER: 'trader',
  ADMIN: 'admin',
  MANAGER: 'manager',
  MANAGER_PP: 'manager_pp',
  ADMIN_PP: 'admin_pp',
  DEALING: 'dealing',
  EXCHANGER: 'exchanger',
  FINANCIAL_OPERATOR: 'financial_operator',
  SUPPORT: 'support',
  MARKETING: 'marketing',
};
